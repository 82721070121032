import axios from "axios"

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMG,
})

AxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      if (error.response.data.status === "DENIED") {
        console.log("Redirecting")
        localStorage.removeItem("tokenDealerOMG")
        localStorage.removeItem("permissionsDealerOMG")
        window.location.href = "/"
      }
    }
    return error
  }
)

if (localStorage.getItem("tokenDealerOMG")) {
  AxiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "tokenDealerOMG"
  )}`
}

export default AxiosInstance
