import { useState, useEffect } from "react"
import { useRecoilState } from "recoil"

import { dealerInfo } from "../atoms/DealerAtom"
import { postApiHelper } from "../util/apiHelpers"

function useSummaryInventory() {
  const dealerInfoValue = useRecoilState(dealerInfo)[0]
  const [irregularRecordIssues, setIrregularRecordIssues] = useState(null)
  const [fTPRecordCounts, setFTPRecordCounts] = useState([])
  const getIrregularRecordIssues = async () => {
    const res = await postApiHelper(
      `DataFiles/getIrregularRecordIssues?dealerId=${dealerInfoValue.dealerID}`
    )

    setIrregularRecordIssues(res.data)
  }
  const getFTPRecordCounts = async () => {
    const res = await postApiHelper(
      `DataFiles/getFTPRecordCounts?dealerId=${dealerInfoValue.dealerID}`
    )

    setFTPRecordCounts(res.data)
  }

  useEffect(() => {
    try {
      getIrregularRecordIssues()
      getFTPRecordCounts()
    } catch (error) {
      console.log(error)
    }
  }, [])

  return { irregularRecordIssues, fTPRecordCounts }
}

export default useSummaryInventory
