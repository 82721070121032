import AxiosInstance from "./axiosInstance"

export async function getApiHelper(url, params = null) {
  try {
    const response = await AxiosInstance.get(url, params)
    return response
  } catch (error) {
    return error
  }
}

export async function postApiHelper(url, body = null, config = null) {
  try {
    const response = await AxiosInstance.post(url, body, config)
    return response
  } catch (error) {
    return error
  }
}

export async function putApiHelper(url, body = null, config = null) {
  try {
    const response = await AxiosInstance.put(url, body, config)
    return response
  } catch (error) {
    return error
  }
}

export async function patchApiHelper(url, body = null, config = null) {
  try {
    const response = await AxiosInstance.patch(url, body, config)
    return response
  } catch (error) {
    return error
  }
}

export async function deleteApiHelper(url, body = null) {
  try {
    const response = await AxiosInstance.delete(url, { data: body })
    return response
  } catch (error) {
    return error
  }
}
