export const changeCurrencyFormat = (value) => {
  const numValue = +value
  return `${numValue.toLocaleString("en-US")}`
}
export const changeStatusFormat = (value) => {
  if (value === 3) {
    return "SOLD"
  } else if (value === 2) {
    return "HOLD"
  }
}
export const changeDateFormat = (date) => {
  const newFormat = new Date(date)

  const pst = newFormat.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  })

  return pst
}

export const changeDateFormatBackend = (date) => {
  if (!date) return ""
  let mm = date.getMonth() + 1
  let dd = date.getDate()
  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm

  return dd + "/" + mm + "/" + date.getFullYear()
}

export const changeDateFormatUS = (date) => {
  if (!date) return ""
  let mm = date.getMonth() + 1
  let dd = date.getDate()
  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm

  return mm + "/" + dd + "/" + date.getFullYear()
}
