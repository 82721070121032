import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"

import {
  recordCountValuesStateCB,
  filtersValuesStateCB,
  SpinerCB,
  FinalWhereClsCBService,
  recordCountNumberCB,
  FinalWhereClsCBSale,
  recordCountNumberActivityCB,
  customerBuilderState,
} from "../../atoms/CustomerBuilderAtom"
import { bigQueryURL } from "../../util/bigQueryURL"
import arrowup from "../../assets/images/arrowup.png"
import SaveMessage from "../Fields/SaveMessage"
import defaultValues from "../../util/defaultValuesCustBuilds"
import { dealerInfo } from "../../atoms/DealerAtom"
import { createSQLListSentence } from "../AudienceCatBuilder/utils"
import { postApiHelper } from "../../util/apiHelpers"

const PrevSerVehRecall = () => {
  const [alert, setAlert] = useState(false)
  const [filterValues, setFilterValues] = useRecoilState(filtersValuesStateCB)
  const dealerInfoValue = useRecoilState(dealerInfo)[0]
  const setRecordCountNumber = useRecoilState(recordCountNumberCB)[1]
  const setRecordCountActivityNumber = useRecoilState(
    recordCountNumberActivityCB
  )[1]
  const sqlSales = useRecoilState(FinalWhereClsCBSale)[0]
  const setSpiner = useRecoilState(SpinerCB)[1]
  const {
    neverPurchased,
    nevSerPrevPurch,
    nevSerDateRange,
    nevSerDateRangePrevSer,
  } = filterValues
  const values = defaultValues
  const setShow = useRecoilState(customerBuilderState)[1]
  const [recordRequest, setRecordRequest] = useRecoilState(
    recordCountValuesStateCB
  )
  const [recall, setRecall] = useState(false)
  const [AdWhereClsAM, setAdWhereClsAM] = useRecoilState(FinalWhereClsCBService)

  const handleSubmit = () => {
    setAlert(true)
    let stringCondition = ""
    if (recall) {
      stringCondition = "'Y'"
    }
    sendRequestCount(stringCondition)
    setFilterValues({
      ...filterValues,
      recallPrevSer: stringCondition,
    })
    setRecordRequest({
      ...recordRequest,
      recallPrevSer: stringCondition,
    })
  }

  const sendRequestCount = (recordRequestBody) => {
    setSpiner(true)
    const sqlClean = { sql: AdWhereClsAM.sql.replace(" AND 1=0", "") }
    const url = bigQueryURL(
      nevSerDateRangePrevSer,
      neverPurchased,
      nevSerPrevPurch,
      nevSerDateRange
    ).url
    const WhereClsAM = createSQLListSentence(
      recordRequestBody,
      "recallPrevSer",
      "RECALL",
      filterValues,
      recordRequest,
      sqlClean,
      false,
      null,
      false
    )
    setAdWhereClsAM({ sql: WhereClsAM })
    const bodyRequest = {
      sqlSales: sqlSales.sql,
      sqlService: WhereClsAM,
      roofTopID: dealerInfoValue.rooftopID,
    }
    postApiHelper(`BigQuery/${url}`, bodyRequest).then((res) => {
      const resBigQuery = res.data[0]
      const recordCountNumber = resBigQuery.numpid
      setRecordCountNumber({ value: recordCountNumber })
      setSpiner(false)
    })
    postApiHelper(`BigQuery/getDVCountDaysWithoutActivity`, bodyRequest).then(
      (res) => {
        const resBigQuery = res.data
        const noActivitySales = resBigQuery[0]?.numpid
        const noActivityService = resBigQuery[1]?.numpid
        setRecordCountActivityNumber({
          valueSales: noActivitySales,
          valueService: noActivityService,
        })
        setSpiner(false)
      }
    )
    return WhereClsAM
  }

  useEffect(() => {
    if (
      filterValues.recallPrevSer !== null &&
      filterValues.recallPrevSer !== undefined
    ) {
      setRecall(filterValues.recallPrevSer)
    }
  }, [])

  useEffect(() => {
    !filterValues.recallPrevSer && setRecall(false)
  }, [filterValues.recallPrevSer])

  const statusChange = (value) => {
    const selectedValue = values.map((obj) => {
      if (obj.name === value) {
        return { ...obj, status: true }
      }
      return obj
    })
    setShow(selectedValue)
  }

  return (
    <>
      <div className="flex justify-center flex-col items-center">
        {alert && filterValues.recallPrevSer !== null && (
          <SaveMessage custBuild={true} />
        )}
        <div className="flex flex-col items-center w-[30%] rounded-xl p-4">
          <fieldset className="h-auto">
            <legend className="sr-only">Recall</legend>
            <h1 className="font-bold text-[#586283] text-[20px] mb-6">
              Recall
            </h1>

            <div className="flex items-center mb-4 justify-center">
              <input
                id="recall"
                checked={recall}
                onChange={(event) => {
                  setRecall(event.target.checked)
                }}
                type="checkbox"
                className="w-4 h-4 text-[#298FC2] bg-[#298fc217] rounded border-gray-300 focus:ring-[#298FC2] focus:ring-2"
              />
              <label
                htmlFor="recall"
                className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Yes
              </label>
            </div>
          </fieldset>
          <button
            onClick={handleSubmit}
            type="button"
            className="flex flex-row justify-between text-white bg-[#298FC2] focus:ring-4 font-bold rounded-lg text-sm px-5 py-2.5 text-center mb-2"
          >
            Submit
            <img className="py-[2px] mx-2" src={arrowup} alt="arrow" />
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center rounded-xl bg-[#E3EBF6] px-2 mt-5">
        <button
          className="bg-white text-[#586283] rounded-lg text-sm px-5 py-2.5 text-center mr-2 my-2"
          onClick={() =>
            statusChange("No Services in a Date Range - Previously Serviced")
          }
        >
          Previous Filter
        </button>
      </div>
    </>
  )
}

export default PrevSerVehRecall
