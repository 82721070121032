import LayoutSold from "../../containers/Sold/LayoutSold"

function Sold() {
  return (
    <div>
      <LayoutSold />
    </div>
  )
}

export default Sold
