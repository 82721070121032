import SecondNavbar from "../../components/SecondNavbar"
import TabsModule from "../../components/TabsModule"

import MainContainer from "./MainContainer"

function LayoutCampaigns() {
  return (
    <div className="flex flex-col bg-[#002E5D] px-[40px] pb-[40px] 2xl:text-[14px] text-[12px]">
      <div className="grid grid-row-2 spx-4 w-full h-[145px] text-[#FFFFFF] 2xl:text-[14px] text-[12px]">
        <SecondNavbar />
        <TabsModule />
      </div>
      <div className="flex  bg-[#F5F9FF] rounded-xl">
        <div className="grid col-span-4 p-2 2xl:text-[14px] text-[12px] min-h-[85vh] w-[60%] grow">
          <MainContainer />
        </div>
      </div>
    </div>
  )
}

export default LayoutCampaigns
