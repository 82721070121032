import LayoutCampaigns from "../../containers/Campaigns/LayoutCampaigns"

function Campaigns() {
  return (
    <div>
      <LayoutCampaigns />
    </div>
  )
}

export default Campaigns
