import { atom } from "recoil"

export const dealerInfo = atom({
  key: "dealerInfo",
  default: {
    dealerID: 1,
    dealerGroup: {
      dealerGroupID: 1,
      dealerGroupName: "",
      createdAt: "",
    },
    businessName: "TestUserIOD",
    address: null,
    address2: null,
    city: null,
    zipCode: null,
    state: null,
    businessLogoUrl: "",
    profileStatus: false,
    crmCompanyUrl: "",
    crmCompanyID: null,
    fb_page_id: null,
    fbaDaccountID: null,
    quickBooksAccountID: null,
    googleAnalytics_GTM_ID: null,
    webStreamID: null,
    dashThisURL: null,
    tierLevel: false,
    randomID: "",
    random_id: "",
    rooftopID: null,
    longitude: null,
    latitude: null,
    cretedAt: "",
    dvDataSource: null,
    user: {
      userID: 1,
      userIdentifier: "",
      userPasswordHash: "",
      firstName: null,
      lastName: null,
      title: null,
      phone: null,
      department: null,
      email: null,
      photo: null,
      status: false,
      policies: null,
    },
    role: {
      roleID: 7,
      roleName: "",
    },
  },
})
