import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"

import {
  filtersValuesState,
  recordCountValuesState,
  recordCountNumber,
  FinalWhereClsAM,
  Spiner,
} from "../../atoms/audienceCatBuilderAtom"
import { dealerInfo } from "../../atoms/DealerAtom"
import { createSQLYesSentence } from "./utils"
import SwitchFilterSubmit from "../Fields/SwitchFilterSubmit"
import SaveMessage from "../Fields/SaveMessage"
import { postApiHelper } from "../../util/apiHelpers"

const VehPurchaseRecall = () => {
  const [alert, setAlert] = useState(false)
  const [ownerWithRecall, setOwnerWithRecall] = useState([
    { name: "Yes", id: "0", selected: false },
  ])

  const [filtersValues, setFiltersValues] = useRecoilState(filtersValuesState)
  const [recordRequest, setRecordRequest] = useRecoilState(
    recordCountValuesState
  )
  const [AdWhereClsAM, setAdWhereClsAM] = useRecoilState(FinalWhereClsAM)
  const setSpiner = useRecoilState(Spiner)[1]
  const setRecordCount = useRecoilState(recordCountNumber)[1]
  const dealerInfoValue = useRecoilState(dealerInfo)[0]

  const recallPersistency = () => {
    const recallList = [{ name: "Yes", id: "0", selected: false }]
    for (let i = 0; i < recallList.length; i++) {
      if (filtersValues.recall) {
        filtersValues.recall &&
          (recallList[i] = { ...recallList[i], selected: true })
      }
    }
  }

  useEffect(() => {
    recallPersistency()
  }, [])

  const changeInput = (event) => {
    const ownerWithRecallAux = [...ownerWithRecall]
    ownerWithRecallAux[
      event.target.id.substring(0, event.target.id.length - 6)
    ].selected = event.target.checked
    setOwnerWithRecall(ownerWithRecallAux)
  }

  const handleRecallChange = () => {
    setAlert(true)
    if (ownerWithRecall[0].selected) {
      sendRequestCount(`'Y'`)
      setFiltersValues({ ...filtersValues, recall: true })
      setRecordRequest({ ...recordRequest, recall: true })
    } else {
      sendRequestCount("")
      setFiltersValues({ ...filtersValues, recall: false })
    }
  }

  const sendRequestCount = (recordRequestBody) => {
    setSpiner(true)
    const WhereClsAM = createSQLYesSentence(
      recordRequestBody,
      "C.RECALL",
      AdWhereClsAM
    )
    setAdWhereClsAM({ sql: WhereClsAM })
    postApiHelper(`BigQuery/getConsumersCountFromBigQuery`, {
      sql: WhereClsAM,
      roofTopID: dealerInfoValue.rooftopID,
      sqlService: filtersValues.excludeService
        ? `${filtersValues.excludeService}`
        : "",
      sqlSales: filtersValues.excludeSales
        ? `${filtersValues.excludeSales}`
        : "",
    }).then((res) => {
      const resBigQuery = res.data[0]
      const resBigQueryExclude = res.data[1]?.numpid
      if (filtersValues.excludeSales && filtersValues.excludeService) {
        setRecordCount({
          value: resBigQuery.numpid,
          amountExcludeService: resBigQueryExclude,
          amountExcludeSales: res.data[2]?.numpid,
        })
      } else {
        setRecordCount({
          value: resBigQuery.numpid,
          amountExcludeSales: filtersValues.excludeSales
            ? resBigQueryExclude
            : null,
          amountExcludeService: filtersValues.excludeService
            ? resBigQueryExclude
            : null,
        })
      }
      setSpiner(false)
    })
  }

  useEffect(() => {
    filtersValues.recall &&
      setOwnerWithRecall([{ name: "Yes", id: "0", selected: true }])
  }, [filtersValues.recall])

  return (
    <div>
      {alert && filtersValues.recall !== null && (
        <SaveMessage custBuild={false} />
      )}
      <div className="flex flex-col items-center">
        <h2 className="font-bold text-[#586283] text-[20px] mb-6">Recall</h2>
        <fieldset className="h-auto">
          <legend className="sr-only">Recall</legend>
          {ownerWithRecall.map((recall) => (
            <div className="flex items-center mb-4" key={recall.id}>
              <input
                id={`${recall.id}recall`}
                checked={recall.selected}
                onChange={(event) => changeInput(event)}
                aria-describedby={`checkbox-${recall.id}recall`}
                type="checkbox"
                className="w-4 h-4 text-[#298FC2] bg-[#298fc217] rounded border-gray-300 focus:ring-[#298FC2] focus:ring-2"
              />
              <label
                htmlFor={`${recall.id}recall`}
                className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {recall.name}
              </label>
            </div>
          ))}
        </fieldset>
      </div>
      <SwitchFilterSubmit
        custBuild={false}
        onSubmit={handleRecallChange}
        next={"Brand Loyalty"}
        prev={"Vehicle Purchase Date"}
      />
    </div>
  )
}

export default VehPurchaseRecall
