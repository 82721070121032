import Layout from "../../containers/Summary/Layout"

function Summary() {
  return (
    <div>
      <Layout />
    </div>
  )
}

export default Summary
