export const defaultFilterValuesAB = {
  pids: null,
  fuelTypeCodes: null,
  ocupationCodes: null,
  makes: null,
  models: null,
  vehicleClasses: null,
  zipCodes: null,
  genders: null,
  mileageCodeList: null,
  inferredPurchasedDates: null,
  allWealthAssests: null,
  homeValues: null,
  childrens: null,
  ageRange: null,
  yearRange: null,
  newMarketScoreRange: null,
  usedMarketScoreRange: null,
  teenagerDriver: null,
  speakSpanish: null,
  veteranInHome: null,
  purshaceLikelihood: null,
  creditRepairInterest: null,
  creditReportInterest: null,
  lenghtResidence: null,
  householdIncome: null,
  zipCodeCenter: null,
  zipCodeRadius: null,
  zipCodesCount: null,
  brandLoyalty: null,
  educationLevel: null,
  excludeService: null,
  excludeSales: null,
  recall: null,
}

export const defaultRecordCountValuesAB = {
  pids: null,
  fuelTypeCodes: null,
  ocupationCodes: null,
  makes: null,
  models: null,
  vehicleClasses: null,
  zipCodes: null,
  genders: null,
  mileageCodeList: null,
  inferredPurchasedDates: null,
  allWealthAssests: null,
  homeValues: null,
  householdIncome: null,
  childrens: null,
  ageRange: { initialValue: 18, finalValue: 99 },
  yearRange: null,
  newMarketScoreRange: null,
  usedMarketScoreRange: null,
  teenagerDriver: null,
  speakSpanish: null,
  veteranInHome: null,
  creditRepairInterest: null,
  creditReportInterest: null,
  educationLevel: null,
  excludeService: null,
  excludeSales: null,
  recall: null,
}

export const defaultFilterValuesCB = {
  states: null,
  neverPurchased: null,
  zipCodes: null,
  yearRange: null,
  prevPurchYear: null,
  zipCodeCenter: null,
  zipCodeRadius: null,
  zipCodesCount: null,
  nevSerDateRange: null,
  nevSerPrevPurch: null,
  prevPurchMake: null,
  prevPurchModel: null,
  prevPurchDateRange: null,
  prevSerMake: null,
  prevSerModel: null,
  prevSerNeverPurch: null,
  prevSerTotalService: null,
  prevSerTotalSpend: null,
  PrevSerDateRange: null,
  prevPurchNewUsed: null,
  nevSerDateRangePrevSer: null,
}

export const defaultRecordCountValuesCB = {
  states: null,
  neverPurchased: null,
  zipCodes: null,
  yearRange: null,
  prevPurchYear: null,
  zipCodeCenter: null,
  zipCodeRadius: null,
  zipCodesCount: null,
  nevSerDateRange: null,
  nevSerPrevPurch: null,
  prevPurchMake: null,
  prevPurchModel: null,
  prevPurchDateRange: null,
  prevSerMake: null,
  prevSerModel: null,
  prevSerNeverPurch: null,
  prevSerTotalService: null,
  prevSerTotalSpend: null,
  PrevSerDateRange: null,
  prevPurchNewUsed: null,
  nevSerDateRangePrevSer: null,
}
