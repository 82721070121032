import { useEffect, useMemo, useState } from "react"
import { useRecoilState } from "recoil"
import { Calendar } from "react-date-range"
import { enUS } from "react-date-range/dist/locale"

import EnhancedTable from "../../components/AdminPanel/EnhancedTable"
import { dealerInfo } from "../../atoms/DealerAtom"
import { changeDateFormatUS, changeDateFormat } from "../../util/changeFormat"
import { getApiHelper } from "../../util/apiHelpers"

const formatCurrency = (value) => {
  let formattedValue = 0
  formattedValue = Number(value.replace(/[^0-9.-]+/g, ""))
  formattedValue =
    "$" +
    formattedValue.toLocaleString("en-US", {
      style: "decimal",
      currency: "USD",
    })
  return formattedValue
}

function createDataCampaignsTable(
  id,
  name,
  objective,
  status,
  startDate,
  endDate,
  budgetType,
  budget,
  optimizationGoal
) {
  return {
    id,
    name,
    objective,
    status,
    startDate,
    endDate,
    budgetType,
    budget,
    optimizationGoal,
  }
}

const headCellsCampaignsTable = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "objective",
    numeric: false,
    disablePadding: false,
    label: "Objective",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
  },
  {
    id: "budgetType",
    numeric: false,
    disablePadding: false,
    label: "Budget Type",
  },
  {
    id: "budget",
    numeric: false,
    disablePadding: false,
    label: "Budget",
  },
  {
    id: "optimizationGoal",
    numeric: false,
    disablePadding: false,
    label: "Optimization Goal",
  },
]

const getSpinner = () => {
  return (
    <svg
      role="status"
      className="inline mr-2 w-60 h-60 text-gray-200 animate-spin dark:text-gray-600 fill-[#586283]"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  )
}

const MainContainer = () => {
  const dealerInfoValue = useRecoilState(dealerInfo)[0]
  const [filterByStartDate, setFilterByStarDate] = useState(true)
  const [loading, setLoading] = useState(false)
  const [campaigns, setCampaigns] = useState([])
  const [filter, setFilter] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    endDate: new Date(),
  })
  const [showDates, setShowDates] = useState({
    startDate: false,
    endDate: false,
  })
  const [minStartDate, setMinStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 4))
  )

  function getCampaigns() {
    if (!dealerInfoValue.fbaDaccountID && !dealerInfoValue.fb_page_id) {
      return
    }
    setLoading(true)
    getApiHelper(`campaigns?dealerID=${dealerInfoValue.dealerID}`)
      .then((res) => {
        if (res.status === 200) {
          const campaignsTmp = res.data.data
          campaignsTmp.sort((a, b) => {
            const dateA = new Date(a.created_time)
            const dateB = new Date(b.created_time)
            return dateA - dateB
          })
          setMinStartDate(new Date(campaignsTmp[0].created_time))
          setCampaigns(campaignsTmp)
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getCampaigns()
  }, [])

  const rowsCampaignsTable = []

  const filteredCampaigns = useMemo(() => {
    return campaigns.filter((campaign) => {
      let startDate = new Date(campaign.created_time)
      let endDate = new Date(campaign.created_time)
      if (!filterByStartDate) {
        startDate = new Date(campaign.stop_time)
        endDate = new Date(campaign.stop_time)
      }
      const filterStartDate = new Date(filter.startDate)
      const filterEndDate = new Date(filter.endDate)
      return startDate >= filterStartDate && endDate <= filterEndDate
    })
  }, [campaigns, filter, filterByStartDate])

  filteredCampaigns.map((campaign) =>
    rowsCampaignsTable.push(
      createDataCampaignsTable(
        campaign.id,
        campaign.name,
        campaign.objective,
        campaign.status,
        changeDateFormat(campaign.created_time).split("T")[0],
        changeDateFormat(campaign.stop_time).split("T")[0],
        campaign.budget_type,
        formatCurrency(campaign.budget),
        campaign.optimization_goal
      )
    )
  )

  const handleChange = (event) => {
    const { name, value } = event.target
    setFilter({ ...filter, [name]: value })
  }

  const handleRefresh = () => {
    getCampaigns()
  }

  return (
    <div className="bg-[#F5F9FF] rounded-xl min-h-[80vh]">
      <div className="flex flex-col items-center">
        <div className="w-full px-[32px] mt-[24px]">
          <section className="mt-[20px] pb-[20px]">
            <h2 className="text-[16px] font-bold text-[#586283]">Campaigns</h2>
            <div className="mt-[20px] flex rounded-[12px] text-[16px] text-[#586283]">
              <div
                className={`flex gap-6 justify-between w-full p-[24px] bg-[#EAEFF5] rounded-[12px]${
                  showDates.startDate || showDates.endDate
                    ? " h-[420px] items-start"
                    : " h-[100px] items-center"
                }`}
              >
                <h2 className="font-bold">Report For</h2>
                <div className="flex gap-2">
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-row items-center justify-center w-[150px]">
                      <input
                        name="filterByStartDate"
                        onChange={() => setFilterByStarDate(true)}
                        checked={filterByStartDate}
                        type="checkbox"
                        className="w-4 h-4 text-[#298FC2] bg-[#298fc217] rounded-full border-gray-300 focus:ring-[#298FC2] focus:ring-2"
                      />
                      <h3 className="ml-[6px]">Start Date</h3>
                    </div>
                    <div className="flex flex-row items-center justify-center w-[150px]">
                      <input
                        name="filterByStartDate"
                        onChange={() => setFilterByStarDate(false)}
                        checked={!filterByStartDate}
                        type="checkbox"
                        className="w-4 h-4 text-[#298FC2] bg-[#298fc217] rounded-full border-gray-300 focus:ring-[#298FC2] focus:ring-2"
                      />
                      <h3 className="ml-[6px]">End Date</h3>
                    </div>
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 items-center">
                      <p className="text-[14px] text-[#2D3032]">Between:</p>
                      <div
                        onClick={() =>
                          setShowDates({
                            endDate: false,
                            startDate: !showDates.startDate,
                          })
                        }
                        className="h-[55px] w-[125px] rounded-xl p-[16px] border-[1px] border-black focus:outline-[#58628325] ml-[15px]"
                      >
                        {changeDateFormatUS(filter.startDate)}
                      </div>
                    </div>
                    {showDates.startDate && (
                      <>
                        <Calendar
                          onChange={(item) =>
                            handleChange({
                              target: { name: "startDate", value: item },
                            })
                          }
                          locale={enUS}
                          date={filter.startDate}
                          minDate={minStartDate}
                          className="rounded-xl p-[16px] focus:outline-[#58628325]"
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() =>
                          setShowDates({
                            startDate: false,
                            endDate: !showDates.endDate,
                          })
                        }
                        className="h-[55px] w-[125px] rounded-xl p-[16px] border-[1px] border-black focus:outline-[#58628325] ml-[15px]"
                      >
                        {changeDateFormatUS(filter.endDate)}
                      </div>
                    </div>
                    {showDates.endDate && (
                      <>
                        <Calendar
                          onChange={(item) =>
                            handleChange({
                              target: { name: "endDate", value: item },
                            })
                          }
                          locale={enUS}
                          date={filter.endDate}
                          className="rounded-xl p-[16px] focus:outline-[#58628325]"
                        />
                      </>
                    )}
                  </div>
                </div>
                <button
                  type="button"
                  onClick={handleRefresh}
                  className="flex items-center h-[45px] w-[103px] justify-around text-white bg-[#298FC2] focus:ring-4 font-bold rounded-lg text-sm px-5 py-2.5 text-center mr-2 my-2"
                >
                  Refresh
                </button>
              </div>
            </div>
            <div className="mt-[20px] flex rounded-[12px] bg-white text-[16px] text-[#586283]">
              {loading ? (
                <div className="flex justify-center w-full h-full">
                  {getSpinner()}
                </div>
              ) : (
                <EnhancedTable
                  headCells={headCellsCampaignsTable}
                  rows={rowsCampaignsTable}
                  titleName="Campaign Summaries"
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default MainContainer
