import React from "react";
import { primeConsultant } from "../../atoms/primeConsultant.js";
import { useRecoilState } from "recoil";

const Support = () => {
  const user = useRecoilState(primeConsultant)[0];

  return (
    <>
      <div className="flex flex-col items-center max-h-[40vh]">
        <h3 className="font-bold text-[#586283] text-[20px]">Support</h3>

        <>
          {user.photo ? (
            <img
              src={user.photo}
              className="h-40 w-40 object-contain"
              alt="Support profile"
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-40 w-40 text-[#586283]"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </>
        <span className="font-bold text-[#586283] text-[18px]">
          {`${user.firstname} ${user.lastname}`}
        </span>
        <span className="font-bold text-[#586283] text-[18px]">
          {user.title}
        </span>
        <span className="font-bold text-[#586283] text-[14px]">
          <strong>Phone:</strong>
          {` ${user.phone}`}
        </span>
        <span className="font-bold text-[#586283] text-[14px]">
          <strong>Email:</strong>
          {` ${user.email}`}
        </span>
      </div>
    </>
  );
};

export default Support;
