import { useRecoilState } from "recoil"

import MainBodyContainer from "./MainBodyContainer"
import LeftSideContainer from "./LeftSideContainer"
import { CollapseLeftBar } from "../../atoms/SideBars"
import SecondNavbar from "../../components/SecondNavbar"
import TabsModule from "../../components/Campaigns/TabsModule"
import CollapsingButton from "../../components/Fields/CollapsingButton"
import useSummaryInventory from "../../Hooks/useSummaryInventory"

function Layout() {
  const leftMenuCollapse = useRecoilState(CollapseLeftBar)[0]
  const { irregularRecordIssues, fTPRecordCounts } = useSummaryInventory()

  return (
    <div className="flex flex-col bg-[#002E5D] px-[40px] pb-[40px] 2xl:text-[14px] text-[12px]">
      <div className="grid grid-row-2 spx-4 w-full h-[145px] text-[#FFFFFF] 2xl:text-[14px] text-[12px]">
        <SecondNavbar />
        <TabsModule />
      </div>
      <div className="flex flex-col bg-[#F5F9FF] rounded-xl min-h-screen">
        <div className=" flex flex-col p-5 gap-3">
          <p className="font-bold text-3xl text-[#586283]">All Profile</p>
          <div className="flex pl-5 justify-between">
            <div className="flex flex-col w-1/3 gap-3 border-2 rounded-lg p-2 m-2">
              <p className="font-bold text-sm text-[#586283]">
                Current Irregular Record Issues
              </p>
              <div className="grid grid-cols-2 gap-1 pl-2">
                {/* <div className="flex gap-5 font-bold">
                  <p className="flex w-32 truncate">Year Less Than 1981</p>
                  <p>{irregularRecordIssues?.yearLess81}</p>
                </div> */}
                <div className="flex gap-5 font-bold">
                  <p className="flex w-32 truncate">Missing VDP URL</p>
                  <p>{irregularRecordIssues?.missingVDPURL}</p>
                </div>
                <div className="flex gap-5 font-bold">
                  <p className="flex w-32 truncate">Missing Make</p>
                  <p>{irregularRecordIssues?.missingMake}</p>
                </div>
                <div className="flex gap-5 font-bold">
                  <p className="flex w-32 truncate">VDP URL No Match</p>
                  <p>{irregularRecordIssues?.wrongVDPURL}</p>
                </div>
                <div className="flex gap-5 font-bold">
                  <p className="flex w-32 truncate">Missing Model</p>
                  <p>{irregularRecordIssues?.missingModel}</p>
                </div>
                <div className="flex gap-5 font-bold">
                  <p className="flex w-32 truncate">Missing Description</p>
                  <p>{irregularRecordIssues?.missingDescription}</p>
                </div>
                <div className="flex gap-5 font-bold">
                  <p className="flex w-32 truncate">Missing Year</p>
                  <p>{irregularRecordIssues?.missingYear}</p>
                </div>
                <div className="flex gap-5 font-bold">
                  <p className="flex w-32 truncate">Zero image URLS</p>
                  <p>{irregularRecordIssues?.missingImageURLs}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-2/3 border-2 rounded-lg p-2 m-2">
              <p className="font-bold text-sm text-[#586283]">
                FTP Files and Record Counts
              </p>
              <div className=" flex flex-col h-[105px] overflow-y-auto">
                <table
                  style={{ borderCollapse: "separate", borderSpacing: "4px" }}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>All</th>
                      <th>Qualified</th>
                      <th>Irregular</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fTPRecordCounts &&
                      fTPRecordCounts.map((fTPRecordCount, index) => (
                        <tr key={index}>
                          <td className="flex font-bold">
                            {fTPRecordCount.file}
                          </td>
                          <td className="text-center font-bold">
                            {new Date(
                              fTPRecordCount.dateAndTime
                            ).toLocaleString("en-US", { hour12: false })}
                          </td>
                          <td className="text-center font-bold">
                            {fTPRecordCount.total}
                          </td>
                          <td className="text-center font-bold">
                            {fTPRecordCount.qualified}
                          </td>
                          <td className="text-center font-bold">
                            {fTPRecordCount.uniques}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          {!leftMenuCollapse ? (
            <CollapsingButton side="left" />
          ) : (
            <div className="w-[20%] p-2 bg-white mr-3 rounded-l-xl">
              <LeftSideContainer />
            </div>
          )}
          <div className="grow w-[60%] pr-4">
            <MainBodyContainer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
