import FilterModule from "../../components/Campaigns/FilterModule"

function LeftSideContainer() {
  return (
    <div className="flex flex-col">
      <FilterModule showPresetSelector={false} />
    </div>
  )
}

export default LeftSideContainer
