export const defaultFilterInventoryBuilder = {
  id: 0,
  dealerId: 0,
  destination: "all",
  typeList: [],
  minDaysOnLot: null,
  maxDaysOnLot: null,
  makeList: [],
  modelList: [],
  year: null,
  vehicleStyleList: [],
  minDealerPrice: null,
  maxDealerPrice: null,
  minMileage: null,
  maxMileage: null,
  inventoryRefill: true,
  dateInventoryRefill: null,
  minViews: null,
  maxViews: null,
  minImageCount: null,
  maxImageCount: null,
  viewLimiter: false,
  oneImageOrLess: false,
  minYear: null,
  maxYear: null,
}

export const defaultFilterSold = {
  typeList: [],
  minDaysOnLot: null,
  maxDaysOnLot: null,
  makeList: [],
  modelList: [],
  vehicleStyleList: [],
  minDealerPrice: null,
  maxDealerPrice: null,
  minMileage: null,
  maxMileage: null,
  minImageCount: null,
  maxImageCount: null,
  minYear: null,
  maxYear: null,
  minDateSold: null,
  maxDateSold: null,
  recordStatus: [2, 3],
}

export const defaultResultModule = {
  typeList: [],
  minDaysOnLot: null,
  maxDaysOnLot: null,
  makeList: [],
  modelList: [],
  vehicleStyleList: [],
  minDealerPrice: null,
  maxDealerPrice: null,
  minMileage: null,
  maxMileage: null,
  minViews: null,
  maxViews: null,
  minImageCount: null,
  maxImageCount: null,
  minYear: null,
  maxYear: null,
}
