import LayoutIrregular from "../../containers/Irregular/LayoutIrregular"

function Irregular() {
  return (
    <div>
      <LayoutIrregular />
    </div>
  )
}

export default Irregular
