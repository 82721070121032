import LayoutAudienceBuilder from "../../containers/AudienceCatBuilder/LayoutAudienceBuilder"

function AudienceBuilder() {
  return (
    <div>
      <LayoutAudienceBuilder />
    </div>
  )
}

export default AudienceBuilder
