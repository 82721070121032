import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"

import {
  recordCountValuesStateCB,
  filtersValuesStateCB,
  SpinerCB,
  FinalWhereClsCBService,
  recordCountNumberCB,
  FinalWhereClsCBSale,
  recordCountNumberActivityCB,
} from "../../atoms/CustomerBuilderAtom"
import { bigQueryURL } from "../../util/bigQueryURL"
import arrowup from "../../assets/images/arrowup.png"
import { createSQLListSentence } from "../AudienceCatBuilder/utils"
import SaveMessage from "../Fields/SaveMessage"
import SwitchFilter from "../Fields/SwitchFilter"
import { dealerInfo } from "../../atoms/DealerAtom"
import { postApiHelper } from "../../util/apiHelpers"

const PrevPurchVehRecall = () => {
  const [alert, setAlert] = useState(false)
  const [filterValues, setFilterValues] = useRecoilState(filtersValuesStateCB)
  const dealerInfoValue = useRecoilState(dealerInfo)[0]
  const setRecordCountNumber = useRecoilState(recordCountNumberCB)[1]
  const setRecordCountActivityNumber = useRecoilState(
    recordCountNumberActivityCB
  )[1]
  const sqlService = useRecoilState(FinalWhereClsCBService)[0]
  const setSpiner = useRecoilState(SpinerCB)[1]
  const {
    neverPurchased,
    nevSerPrevPurch,
    nevSerDateRange,
    nevSerDateRangePrevSer,
  } = filterValues
  const [recordRequest, setRecordRequest] = useRecoilState(
    recordCountValuesStateCB
  )
  const [recall, setRecall] = useState(false)
  const [AdWhereClsAM, setAdWhereClsAM] = useRecoilState(FinalWhereClsCBSale)

  const handleSubmit = () => {
    setAlert(true)
    let stringCondition = ""
    if (recall) {
      stringCondition = "'Y'"
    }
    sendRequestCount(stringCondition)
    setFilterValues({
      ...filterValues,
      recallPrevPurch: stringCondition,
    })
    setRecordRequest({
      ...recordRequest,
      recallPrevPurch: stringCondition,
    })
  }

  const sendRequestCount = (recordRequestBody) => {
    setSpiner(true)
    const sqlClean = { sql: AdWhereClsAM.sql.replace(" AND 1=0", "") }
    const url = bigQueryURL(
      nevSerDateRangePrevSer,
      neverPurchased,
      nevSerPrevPurch,
      nevSerDateRange
    ).url
    const WhereClsAM = createSQLListSentence(
      recordRequestBody,
      "recallPrevPurch",
      "RECALL",
      filterValues,
      recordRequest,
      sqlClean,
      false,
      null,
      false
    )
    setAdWhereClsAM({ sql: WhereClsAM })
    const bodyRequest = {
      sqlSales: WhereClsAM,
      sqlService: sqlService.sql,
      roofTopID: dealerInfoValue.rooftopID,
    }
    postApiHelper(`BigQuery/${url}`, bodyRequest).then((res) => {
      const resBigQuery = res.data[0]
      const recordCountNumber = resBigQuery.numpid
      setRecordCountNumber({ value: recordCountNumber })
      setSpiner(false)
    })
    postApiHelper(`BigQuery/getDVCountDaysWithoutActivity`, bodyRequest).then(
      (res) => {
        const resBigQuery = res.data
        const noActivitySales = resBigQuery[0]?.numpid
        const noActivityService = resBigQuery[1]?.numpid
        setRecordCountActivityNumber({
          valueSales: noActivitySales,
          valueService: noActivityService,
        })
        setSpiner(false)
      }
    )
    return WhereClsAM
  }

  useEffect(() => {
    if (
      filterValues.recallPrevPurch !== null &&
      filterValues.recallPrevPurch !== undefined
    ) {
      setRecall(filterValues.recallPrevPurch)
    }
  }, [])

  useEffect(() => {
    !filterValues.recallPrevPurch && setRecall(false)
  }, [filterValues.recallPrevPurch])

  return (
    <>
      <div className="flex justify-center flex-col items-center">
        {alert && filterValues.recallPrevPurch !== null && (
          <SaveMessage custBuild={true} />
        )}
        <div className="flex flex-col items-center w-[30%] rounded-xl p-4">
          <fieldset className="h-auto">
            <legend className="sr-only">Recall</legend>
            <h1 className="font-bold text-[#586283] text-[20px] mb-6">
              Recall
            </h1>

            <div className="flex items-center mb-4 justify-center">
              <input
                id="recall"
                checked={recall}
                onChange={(event) => {
                  setRecall(event.target.checked)
                }}
                type="checkbox"
                className="w-4 h-4 text-[#298FC2] bg-[#298fc217] rounded border-gray-300 focus:ring-[#298FC2] focus:ring-2"
              />
              <label
                htmlFor="recall"
                className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Yes
              </label>
            </div>
          </fieldset>
          <button
            onClick={handleSubmit}
            type="button"
            className="flex flex-row justify-between text-white bg-[#298FC2] focus:ring-4 font-bold rounded-lg text-sm px-5 py-2.5 text-center mb-2"
          >
            Submit
            <img className="py-[2px] mx-2" src={arrowup} alt="arrow" />
          </button>
        </div>
      </div>
      <SwitchFilter
        next={"Services in a Date Range"}
        prev={"No Services in a Date Range - Previously Purchased"}
        custBuild={true}
      />
    </>
  )
}

export default PrevPurchVehRecall
